<template>
  <b-input-group class="b-form-timepicker">
    <b-input-group-prepend>
      <b-dropdown variant="outline-secondary" no-caret :size="size" :disabled="$attrs.disabled">
        <template #button-content>
          <BIconClock />
        </template>
        <li>
          <div class="px-2 d-flex gap-1 align-items-center">
            <b-form-spinbutton :value="parsedHours" :formatter-fn="spinValueFormatter" :min="minHour" :max="maxHour" vertical wrap @change="updateHours" />
            <span class="h4 mb-1">:</span>
            <b-form-spinbutton :value="parsedMinutes" :formatter-fn="spinValueFormatter" min="0" max="59" vertical wrap @change="updateMinutes" />
            <span class="h4 mb-1">:</span>
            <b-form-spinbutton :value="parsedSeconds" :formatter-fn="spinValueFormatter" min="0" max="59" vertical wrap @change="updateSeconds" />
          </div>
        </li>
      </b-dropdown>
    </b-input-group-prepend>
    <the-mask
      v-model="modelValue"
      v-bind="$attrs"
      type="text"
      :placeholder="$t('table.pickTime')"
      class="form-control"
      :class="{ 'form-control-sm': size === 'sm' }"
      mask="FL:ML:ML"
      minlength="8"
      :tokens="timeTokens"
      :masked="true"
    />
  </b-input-group>
</template>

<script>
import { BDropdown, BIconClock, BFormSpinbutton } from 'bootstrap-vue';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';

export default {
  components: { BDropdown, BIconClock, BFormSpinbutton },
  mixins: [timeConvertHelpers],
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * Set input and button size. 'sm' for small
     * @default undefined
     * @type {String}
     */
    size: {
      type: String,
      default: undefined,
    },
    /**
     * Time value from broadcast day start time to the end (default '06:00:00' to '29:59:59')
     * @default undefined
     * @type {String, Number}
     */
    value: {
      type: [String, Number],
      default: undefined,
    },
    /**
     * To disable min hour validation
     */
    noStartTimeLimit: { type: Boolean, default: undefined },
    /**
     * To set max hour validation
     */
    maxHours: { type: Number, default: undefined },
    /**
     * To use interval as field value
     */
    intervalMode: { type: Boolean, default: undefined },
  },
  data() {
    const envMinHourInSec = process.env.VUE_APP_BROADCAST_START_TIME ? +process.env.VUE_APP_BROADCAST_START_TIME : 21600; //default 06:00:00
    const minHour = Math.floor(envMinHourInSec / 60 / 60);
    const maxHour = 24 + minHour - 1;
    return {
      minBroadcastHour: minHour,
      maxBroadcastHour: maxHour,
      timeTokens: {
        F: { pattern: /[0-2]/ },
        M: { pattern: /[0-5]/ },
        L: { pattern: /[0-9]/ },
      },
    };
  },
  computed: {
    minHour() {
      return this.noStartTimeLimit ? 0 : this.minBroadcastHour;
    },

    maxHour() {
      return this.maxHours || this.maxBroadcastHour;
    },

    modelValue: {
      get() {
        return this.intervalMode ? (this.value === null || this.value === undefined || this.value === '' ? '' : this.sec2time(this.value)) : this.value;
      },
      set(value) {
        if (this.intervalMode) {
          if (!value) this.$emit('input', null);
          else if (value.length === 8) this.$emit('input', this.time2sec(value));
        } else this.$emit('input', value);
      },
    },
    parsedHours() {
      const val = this.intervalMode ? this.sec2time(this.value) : this.value;
      return this.value === null || this.value === undefined || this.value === '' ? '' : parseInt(val?.substring(0, 2));
    },
    parsedMinutes() {
      const val = this.intervalMode ? this.sec2time(this.value) : this.value;
      return this.value === null || this.value === undefined || this.value === '' ? '' : parseInt(val?.substring(3, 5));
    },
    parsedSeconds() {
      const val = this.intervalMode ? this.sec2time(this.value) : this.value;
      return this.value === null || this.value === undefined || this.value === '' ? '' : parseInt(val?.substring(6, 8));
    },
  },
  methods: {
    //visual formatter for '0X' instead of 'X'
    spinValueFormatter(value) {
      return `${('00' + value).slice(-2)}`;
    },

    updateHours(value) {
      this.emitTimeString('hours', value);
    },
    updateMinutes(value) {
      this.emitTimeString('minutes', value);
    },
    updateSeconds(value) {
      this.emitTimeString('seconds', value);
    },

    //triggers only on user's @change
    emitTimeString(type, value) {
      if (this.intervalMode) {
        let timeInterval = type === 'hours' ? +value * 60 * 60 : this.parsedHours !== '' ? this.parsedHours * 60 * 60 : 0;
        timeInterval += type === 'minutes' ? +value * 60 : this.parsedMinutes !== '' ? this.parsedMinutes * 60 : 0;
        timeInterval += type === 'seconds' ? +value : this.parsedSeconds !== '' ? this.parsedSeconds : 0;
        this.$emit('input', timeInterval);
      } else {
        let timeString = (type === 'hours' ? ('00' + value ?? 0).slice(-2) : this.parsedHours !== '' ? ('00' + this.parsedHours ?? 0).slice(-2) : '') + ':';
        timeString += (type === 'minutes' ? ('00' + value ?? 0).slice(-2) : this.parsedMinutes !== '' ? ('00' + this.parsedMinutes ?? 0).slice(-2) : '') + ':';
        timeString += type === 'seconds' ? ('00' + value ?? 0).slice(-2) : this.parsedSeconds !== '' ? ('00' + this.parsedSeconds ?? 0).slice(-2) : '';
        this.$emit('input', timeString);
      }
    },
  },
};
</script>
